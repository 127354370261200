<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      아이디 찾기<router-link to="/member/login"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="search_idpw">
      <!--아이디 없을때-->
      <h3 v-if="!userid">입력하신 정보의 해당 계정을 찾을 수 없습니다.</h3>

      <div class="result" v-if="!userid">
        <div class="top">
          입력하신 정보에 해당하는 계정을 찾을 수 없습니다.<br />아래
          <b>[※ 위에 가입한 아이디가 없나요?]</b>를 읽어보시고 다른 해결책을
          찾아보세요.
        </div>
        <ul>
          <li>
            <h4>◎ 입력 정보에 해당하는 아이디 없음</h4>
          </li>
        </ul>
      </div>

      <!--아이디 있을때-->
      <h3 v-if="userid">아래 회원님의 정보와 일치하는 아이디입니다.</h3>

      <div class="result" v-if="userid">
        <div class="top">
          아래에서 <b>[로그인]</b> 버튼을 누르면 로그인 페이지로 이동합니다.
          비밀번호를 잊었다면 아래에서 <b>[비밀번호 찾기]</b> 버튼을 눌러주세요.
        </div>
        <ul>
          <li>
            <h4>◎ 해당 아이디 : {{ userid }}</h4>
            <span>(가입 : {{ registDate }})</span>
          </li>
        </ul>
      </div>

      <div class="btn">
        <router-link to="/member/login" class="yellow">로그인</router-link
        ><router-link to="/member/find_pw" class="white"
          >비밀번호 찾기</router-link
        >
      </div>

      <div class="info">
        <div class="box">
          <div class="top">
            ※ 위에 가입한 아이디가 없나요?<router-link
              to=""
              @click.native="open($event)"
              >∨</router-link
            >
          </div>
          <ul style="display: none">
            <li>
              위에 아이디가 없다면 회원가입 시 또는 이후 등록/수정한 정보와 방금
              입력하신 정보가 달라서일 수 있습니다. 이름과 전화번호가 회원가입
              시 또는 이후 등록/수정한 정보와 일치하는지 확인한 후 인증 절차를
              다시 한번 진행해주세요.
            </li>
            <li>
              인증 절차를 제대로 진행했음에도 위에 아이디가 없다면 원래 없는
              아이디이거나 회원님이 관련 법규 또는 회사의 규정을 어겨 계정
              삭제된 아이디일 수 있습니다.
            </li>
          </ul>
        </div>
        <div class="box">
          <div class="top">
            ※ 위에 가입하지 않은 아이디가 보이나요?<router-link
              to=""
              @click.native="open($event)"
              >∨</router-link
            >
          </div>
          <ul style="display: none">
            <li>
              누군가가 회원님의 명의를 도용해 계정을 만들었을 수 있습니다. 이
              경우 고객센터에 신고해주세요.<br /><a href="#"
                >고객센터 신고하기 ▶</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    if (!this.isCheckPhone) {
      this.$toast.default("휴대폰 인증이 필요합니다.");
      this.$router.push("/member/find_id");
    }
  },
  methods: {
    open(e) {
      const ulEl = e.target.closest(".box").querySelector("ul");
      if (ulEl.style.display == "none") {
        e.target.textContent = "∧";
        ulEl.style.display = "block";
      } else {
        e.target.textContent = "∨";
        ulEl.style.display = "none";
      }
    },
  },
  computed: {
    ...mapState("member", [
      "msg",
      "result",
      "userid",
      "registDate",
      "isCheckPhone",
    ]),
  },
};
</script>

<style></style>
